import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { isAxiosError, isApiErrorResponseData } from "@a/axios-error-handler";
import { useAuth } from "@/store/auth/auth";
import { isObject } from "lodash";

export function api(options?: AxiosRequestConfig): AxiosInstance {
  const { state: authState } = useAuth();
  const { jwt: token } = authState;

  const { NODE_ENV, VUE_APP_API_BASE_URL } = process.env;

  const defaultOptions: AxiosRequestConfig = {
    baseURL: ["production", "homolog"].includes(NODE_ENV) ? VUE_APP_API_BASE_URL : "http://localhost:8581/",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  return axios.create({ ...defaultOptions, ...options });
}

/**
 * Extrai as colunas / campos do erro da api, caso este seja um erro
 * de violacao de campos unique
 */
export function getFormUniqueConstraintErrors(error: Error): string[] {
  if (!isAxiosError(error) || !isApiErrorResponseData(error.response?.data)) return [];

  // @ts-ignore
  const msg = error.response?.data?.message;

  if (!isObject(msg) || Array.isArray(msg)) return [];

  // @ts-ignore
  return (msg.columns as string[]) ?? [];
}
