/* 
 | Arquivo gerado pelo script gen-enum-permissoes em:
 | 28/09/2021
 | 
 | para sincronizar esse enum com as permissoes do banco
 | basta roda-lo novamente (yarn gen:enum-permissoes).
 | 
 | não modificar esse arquivo diretamente. 
 | 
 */
export enum PERMISSAO {
  SELECT_USUARIO = "select_usuario",
  INSERT_USUARIO = "insert_usuario",
  UPDATE_USUARIO = "update_usuario",
  UPDATE_CREDENCIAIS_USUARIO = "update_credenciais_usuario",
  SOFT_DELETE_USUARIO = "soft_delete_usuario",
  RESTORE_USUARIO = "restore_usuario",
  SELECT_NIVEL_ACESSO = "select_nivel_acesso",
  INSERT_NIVEL_ACESSO = "insert_nivel_acesso",
  UPDATE_NIVEL_ACESSO = "update_nivel_acesso",
  DELETE_NIVEL_ACESSO = "delete_nivel_acesso",
  SELECT_SERVICO_MONITORAMENTO = "select_servico_monitoramento",
  INSERT_SERVICO_MONITORAMENTO = "insert_servico_monitoramento",
  UPDATE_SERVICO_MONITORAMENTO = "update_servico_monitoramento",
  DELETE_SERVICO_MONITORAMENTO = "delete_servico_monitoramento",
  INSERT_FICHA = "insert_ficha",
  SELECT_FICHA = "select_ficha",
  SELECT_FICHA_LIBERADA = "select_ficha_liberada",
  RELEASE_FICHA = "release_ficha",
  EVALUATE_FICHA = "evaluate_ficha",
  REVIEW_FICHA = "review_ficha",
  RELOCATE_FICHA = "relocate_ficha",
  DELETE_FICHA = "delete_ficha",
  SELECT_TENANCIA = "select_tenancia",
  INSERT_TENANCIA = "insert_tenancia",
  UPDATE_TENANCIA = "update_tenancia",
  DELETE_TENANCIA = "delete_tenancia",
}

export enum NOME_PERMISSAO {
  SELECT_USUARIO = "Visualizar usuários",
  INSERT_USUARIO = "Cadastrar usuários",
  UPDATE_USUARIO = "Atualizar usuários",
  UPDATE_CREDENCIAIS_USUARIO = "Atualizar credenciais de usuários",
  SOFT_DELETE_USUARIO = "Desativar usuários",
  RESTORE_USUARIO = "Reativar usuários",
  SELECT_NIVEL_ACESSO = "Visualizar níveis de acesso",
  INSERT_NIVEL_ACESSO = "Cadastrar níveis de acesso",
  UPDATE_NIVEL_ACESSO = "Atualizar níveis de acesso",
  DELETE_NIVEL_ACESSO = "Deletar níveis de acesso",
  SELECT_SERVICO_MONITORAMENTO = "Visualizar serviços monitoramento",
  INSERT_SERVICO_MONITORAMENTO = "Cadastrar serviço monitoramento",
  UPDATE_SERVICO_MONITORAMENTO = "Atualizar serviço monitoramento",
  DELETE_SERVICO_MONITORAMENTO = "Deletar serviço monitoramento",
  INSERT_FICHA = "Cadastrar ficha",
  SELECT_FICHA = "Visualizar todas as fichas",
  SELECT_FICHA_LIBERADA = "Visualizar fichas liberadas",
  RELEASE_FICHA = "Liberar ficha",
  EVALUATE_FICHA = "Avaliar ficha",
  REVIEW_FICHA = "Revisar fichas",
  DELETE_FICHA = "Deletar fichas",
  SELECT_TENANCIA = "Visualizar grupos",
  INSERT_TENANCIA = "Cadastrar grupos",
  UPDATE_TENANCIA = "Atualizar grupos",
  DELETE_TENANCIA = "Deletar grupos",
}
