import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60705b53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutSidebarMenuItems = _resolveComponent("LayoutSidebarMenuItems")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LayoutSidebarMenuItems, {
      items: _ctx.items,
      class: "layout-menu",
      root: "",
      onMenuitemClick: _ctx.onMenuItemClick
    }, null, 8, ["items", "onMenuitemClick"])
  ]))
}