import { apiCadastraFichaTerraplenoContencao, CreateFichaTerraplenoContencaoDTO } from "@/api/ficha-api";
import { loadFromLS, syncWithLS } from "../persist-state";
import { reactive, readonly } from "vue";
import { AxiosError } from "axios";
import { cloneDeep } from "lodash";

/*
 | Sincronização de fichas é o processo de enviar as fichas salvas 
 | no dispositivo para a API e remove-las do dispositivo em sucesso.
 |  
 | Como o usuário ainda pode usar o APP enquando a sincronização
 | ocorre ela deve ser uma operação que roda em background e não
 | fica presa ao escopo de um componente, por isso esse estado global.
 */

export interface TentativaSincronizacaoFicha {
  /**
   * Identificador da ficha da tentativa de sync
   */
  uuid: string;

  sucesso: boolean;
  estavaPreviamenteSincronizada?: boolean;

  tipo: "terrapleno_contencao";

  /**
   * ISO 8601 da data em que a sync ocorreu
   */
  data_sincronizacao: string;

  /**
   * AxiosError.response.data
   */
  errorResponseData?: any;
}

interface SincronizadorState {
  /**
   * Se há uma sincronização em andamento
   */
  isSynchronizing: boolean;

  /**
   * Se a sincronização em andamento deve ser cancelada
   */
  shouldCancelSync: boolean;

  fichasEmSincronizacao: CreateFichaTerraplenoContencaoDTO[];

  tentativas: TentativaSincronizacaoFicha[];
}

type fichasCadastraveis = CreateFichaTerraplenoContencaoDTO[];

type onSyncSuccessCb = (ficha: CreateFichaTerraplenoContencaoDTO) => unknown;
type onSyncFailureCb = (error: AxiosError, ficha: CreateFichaTerraplenoContencaoDTO) => unknown;

interface SyncFichasTerraplenoArgs {
  fichasToSync: CreateFichaTerraplenoContencaoDTO[];
  idMonitoramento: number;
  onSuccessCb?: onSyncSuccessCb;
  onFailureCb?: onSyncFailureCb;
}

const state: SincronizadorState = reactive(
  loadFromLS("fichaSyncState", {
    isSynchronizing: false,
    shouldCancelSync: false,
    fichasEmSincronizacao: [],
    tentativas: [],
  })
);

// Persisto os dados do sincronizador na LS
syncWithLS({ fichaSyncState: state });

// Reseto o estado de sincronização carregado da LS
// pois é impossível que eu esteja sincronizando ao
// carregar o modulo e é possivel que o usuário tenha
// fechado a pagina enquanto a sincronização ocorria,
// deixando o state na LS errado
state.fichasEmSincronizacao = [];
state.isSynchronizing = false;
state.shouldCancelSync = false;

const syncFichaTerrapleno = async (args: SyncFichasTerraplenoArgs) => {
  const { fichasToSync, idMonitoramento, onFailureCb, onSuccessCb } = args;

  state.isSynchronizing = true;
  state.fichasEmSincronizacao = [...fichasToSync];

  let attemptsFailedDueToNetworkError = 0;

  // Remove uma ficha do array de fichas em sync, lembrando que nao posso usar
  // o idx do loop abaixo pois conforme eu remomovo o tamanho dos arrays difere
  // e logo as fichas apontadas pelo idx também
  const removeFichaFromFichasEmSyncState = (ficha: CreateFichaTerraplenoContencaoDTO) => {
    const idx = state.fichasEmSincronizacao.findIndex((fichaEmSync) => fichaEmSync.uuid === ficha.uuid);
    state.fichasEmSincronizacao.splice(idx, 1);
  };

  for (let index = 0; index < fichasToSync.length; index++) {
    const ficha = fichasToSync[index];

    ficha.id_monitoramento = idMonitoramento;
    if (!ficha.altura_terrapleno) ficha.altura_terrapleno = 1;

    await apiCadastraFichaTerraplenoContencao(ficha)
      .then(() => {
        if (onSuccessCb) onSuccessCb(ficha);

        state.tentativas.push({
          uuid: ficha.uuid,
          sucesso: true,
          estavaPreviamenteSincronizada: false,
          tipo: "terrapleno_contencao",
          data_sincronizacao: new Date().toISOString(),
        });
      })
      .catch((error: AxiosError) => {
        // @ts-ignore
        const fichaJaFoiSincronizada = error.response?.data?.message?.errorCode === "FICHA_UUID_IN_USE";

        if (fichaJaFoiSincronizada) {
          if (onSuccessCb) onSuccessCb(ficha);

          state.tentativas.push({
            uuid: ficha.uuid,
            sucesso: true,
            estavaPreviamenteSincronizada: true,
            tipo: "terrapleno_contencao",
            data_sincronizacao: new Date().toISOString(),
          });
        } else {
          if (onFailureCb) onFailureCb(error, ficha);

          state.tentativas.push({
            uuid: ficha.uuid,
            sucesso: false,
            tipo: "terrapleno_contencao",
            data_sincronizacao: new Date().toISOString(),
            errorResponseData: error.response?.data,
          });

          // Se não obtive resposta / servidor não pode ser alcançado
          if (!error.response) attemptsFailedDueToNetworkError++;
        }
      })
      .finally(() => {
        removeFichaFromFichasEmSyncState(ficha);
      });

    // Se foi cancelada manualmente paro
    if (state.shouldCancelSync) break;

    // Se ultrapassou esse limite arbritario de erros de conexão
    // assumo que toda a sincronização falharia e paro de sincronizar
    if (attemptsFailedDueToNetworkError > 3) break;
  }

  // Caso a sincronização tenha sido cancelada tenho que limpar o array
  state.fichasEmSincronizacao = [];
  state.shouldCancelSync = false;

  state.isSynchronizing = false;
};

/**
 * Sincroniza fichas com a API, registrando a tentativa no state
 *
 * @param idMonitoramento - Monitoramento a associar as fichas
 */
const syncFichas = (
  fichasToSync: fichasCadastraveis,
  idMonitoramento: number,
  tipoFichas: "terrapleno_contencao",
  onSuccessCb?: onSyncSuccessCb,
  onFailureCb?: onSyncFailureCb
) => {
  const fichas = cloneDeep(fichasToSync);
  if (tipoFichas === "terrapleno_contencao") {
    syncFichaTerrapleno({ fichasToSync: fichas, idMonitoramento, onSuccessCb, onFailureCb });
  }
};

const CLEAR_LOGS_TENTATIVAS = () => {
  state.tentativas = [];
};

const CANCELA_SINCRONIZACAO = () => {
  if (state.isSynchronizing) state.shouldCancelSync = true;
};

export const useSincronizadorFicha = () => ({
  syncFichas,
  CLEAR_LOGS_TENTATIVAS,
  CANCELA_SINCRONIZACAO,

  state: readonly(state),
});
