import { computed, onMounted, onUnmounted, ref } from "vue";

/*
 | Breakpoints: 
 | 
 | Aqui devem ficar toda e qualquer logica quanto
 | a responsividade, tamanhos de dispositivos, etc.
 */

export function useBreakpoints() {
  let innerWidth = ref(window.innerWidth);

  const onWidthChange = () => (innerWidth.value = window.innerWidth);

  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const displayType = computed(() => {
    if (innerWidth.value < 550) return "xs";
    if (innerWidth.value < 1200) return "md";
    return "lg";
  });

  const width = computed(() => innerWidth.value);
  const isDesktop = computed(() => innerWidth.value > 1024);

  return { width, displayType, isDesktop };
}
