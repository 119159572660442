import { RouteRequiredPermissions } from "./router";
import { PERMISSAO } from "@/constants/permissoes";

interface BaseSidebarItem {
  visible?: () => boolean | boolean;
  disabled?: boolean;
  label: string;
  icon: string;
  requireLogin?: boolean;
  requireLogoff?: boolean;
}

interface SidebarRoute extends BaseSidebarItem {
  items?: never;
  url?: never;
  to: string;
  requiredPermissions?: RouteRequiredPermissions;
}

interface SidebarExternalRoute extends BaseSidebarItem {
  items?: never;
  to?: never;
  url: string;
  requiredPermissions?: RouteRequiredPermissions;
}

interface SidebarRouteMenu extends BaseSidebarItem {
  to?: never;
  url?: never;
  requiredPermissions?: never;
  items: (SidebarRoute | SidebarRouteMenu)[];
}

export type sidebarItem = SidebarRouteMenu | SidebarRoute | SidebarExternalRoute;

/**
 * Rotas da aplicacao que sao exibidas na sidebar da dashboard
 */
export const sidebarRoutes: sidebarItem[] = [
  {
    label: "Home",
    icon: "pi pi-fw pi-home",
    to: "/",
    requireLogin: true,
  },
  {
    label: "Meu Perfil",
    icon: "pi pi-fw pi-user",
    to: "/meu-perfil",
    requireLogin: true,
  },
  {
    label: "Usuários e Permissões",
    icon: "pi pi-fw pi-user-edit",
    items: [
      {
        label: "Usuários",
        icon: "pi pi-fw pi-users",
        to: "/usuarios",
        requiredPermissions: PERMISSAO.SELECT_USUARIO,
      },
      {
        label: "Níveis de Acesso",
        icon: "pi pi-fw pi-tags",
        to: "/niveis-acesso",
        requiredPermissions: PERMISSAO.SELECT_NIVEL_ACESSO,
      },
    ],
  },
  {
    label: "Grupos",
    icon: "pi pi-fw pi-th-large",
    to: "/grupos",
    requireLogin: true,
    requiredPermissions: PERMISSAO.SELECT_TENANCIA,
  },
  {
    label: "Monitoramento",
    icon: "pi pi-fw pi-sitemap",
    items: [
      {
        label: "Mapa",
        icon: "pi pi-fw pi-map-marker",
        to: "/rodoviario/mapa",
        requiredPermissions: {
          some: true,
          value: [PERMISSAO.SELECT_FICHA, PERMISSAO.SELECT_FICHA_LIBERADA],
        },
      },
      {
        label: "Serviços",
        icon: "pi pi-fw pi-table",
        to: "/rodoviario/servicos",
        requiredPermissions: [PERMISSAO.SELECT_SERVICO_MONITORAMENTO],
      },
      {
        label: "Entrevias 2022",
        icon: "pi pi-fw pi-list",
        to: "/rodoviario/relocating",
        requiredPermissions: [PERMISSAO.RELOCATE_FICHA],
      },
    ],
  },
  {
    label: "Meu Dispositivo",
    icon: "pi pi-fw pi-mobile",
    items: [
      {
        label: "Mapa",
        icon: "pi pi-fw pi-map-marker",
        to: "/offline/rodoviario/mapa",
      },
      {
        label: "Fichas",
        icon: "pi pi-fw pi-table",
        to: "/offline/rodoviario/minhas-fichas",
      },
    ],
  },
];
