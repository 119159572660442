
import { useBreakpoints } from "@/store/theme/breakpoints";
import { computed, defineComponent, ref } from "vue";
import { useTheme } from "../store/theme/theme";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  components: { RadioButton },

  setup() {
    const isMenuVisible = ref(false);

    const containerClass = computed(() => ["layout-config", { "layout-config-active": isMenuVisible.value }]);

    const onClickOutside = () => {
      if (isMenuVisible.value) isMenuVisible.value = false;
    };

    const { isDesktop } = useBreakpoints();

    const theme = useTheme();

    return { ...theme, onClickOutside, containerClass, isMenuVisible, isDesktop };
  },
});
