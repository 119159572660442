import { DirectiveBinding } from "@vue/runtime-core";
import { isPermissionObj } from "@/router/router";
import { userCan } from "@/store/auth/auth";

/**
 * Diretiva de autorização, verifica se o usuário
 * logado tem as permissoes passadas nos bindings
 *
 * essa diretiva apenas esconde o elemento, para
 * evitar que ele seja renderizao utilize um v-if
 * com o método global $can
 *
 * ex: v-if="$can(requiredPermissions)"
 */
export default {
  beforeMount(el: HTMLElement, { value: requiredPermissions, modifiers }: DirectiveBinding) {
    if (!requiredPermissions) return;

    const can = isPermissionObj(requiredPermissions)
      ? userCan(requiredPermissions.value, { some: true })
      : userCan(requiredPermissions, { some: modifiers.some });

    if (!can) el.style.display = "none";
  },
};
