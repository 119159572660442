
import { computed, defineComponent, PropType, Ref, ref } from "vue";
import { sidebarItem } from "@/router/dashboard-sidebar-routes";
import { useAuth } from "@/store/auth/auth";

export default defineComponent({
  // Deve ter esse nome por causa da recursão
  name: "LayoutSubmenu",

  props: {
    /**
     * Items do menu a serem exibidos
     */
    items: {
      type: Array as PropType<sidebarItem[]>,
      default: () => [],
    },

    /**
     * Indica se é o item raiz do menu
     */
    root: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["menuitem-click"],

  setup(props, { emit }) {
    const activeIndex: Ref<number | null> = ref(null);

    function onMenuItemClick(event: Event, item: sidebarItem, index: number) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) event.preventDefault();

      activeIndex.value = index === activeIndex.value ? null : index;

      emit("menuitem-click", { originalEvent: event, item });
    }

    const visible = (item: sidebarItem) => {
      return typeof item.visible === "function" ? item.visible() : item.visible !== false;
    };

    const { userCan, isLoggedIn } = useAuth();

    const filterForbiddenRoutes = (items: sidebarItem[]): sidebarItem[] => {
      return items.filter((item) => {
        if (item.items) return filterForbiddenRoutes(item.items).length > 0;
        if (item.requireLogin && !isLoggedIn.value) return false;
        if (!item.requiredPermissions) return true;

        return typeof item.requiredPermissions === "object" && !Array.isArray(item.requiredPermissions)
          ? userCan(item.requiredPermissions.value, { some: true })
          : userCan(item.requiredPermissions);
      });
    };

    const allowedItems = computed((): sidebarItem[] => filterForbiddenRoutes(props.items));

    return { onMenuItemClick, visible, activeIndex, allowedItems };
  },
});
