import { getHomeRoute } from "../router-utils";
import { AppRoute } from "../router";

export const commonRoutes: AppRoute[] = [
  {
    path: "/",
    redirect: getHomeRoute,
  },
  {
    path: "/home",
    component: () => import("@p/HomePage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@p/error/PageNotFound.vue"),
  },
];
