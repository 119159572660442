
import LayoutSidebarMenu, { SideBarMenuItemClickEvent } from "./LayoutSidebarMenu.vue";
import LayoutSidebarProfile from "./LayoutSidebarProfile.vue";

import { sidebarRoutes } from "../../router/dashboard-sidebar-routes";
import { useBreakpoints } from "@/store/theme/breakpoints";
import { computed, defineComponent } from "vue";
import { useTheme } from "@/store/theme/theme";
import { isElement } from "@/typescript/guards";
import { useRoute } from "vue-router";
import { useAuth } from "@/store/auth/auth";

export default defineComponent({
  components: {
    LayoutSidebarMenu,
    LayoutSidebarProfile,
  },

  setup() {
    const { layoutColor, isSidebarActive, sidebarMode, CLOSE_SIDEBAR } = useTheme();
    const { isDesktop } = useBreakpoints();
    const route = useRoute();

    const sidebarClass = computed(() => [
      "layout-sidebar",
      {
        "layout-sidebar-dark": layoutColor.value === "dark",
        "layout-sidebar-light": layoutColor.value === "light",
        "layout-sidebar-mobile-with-header": !isDesktop.value && route.meta.hideHeader !== true,
      },
    ]);

    const shouldRenderSidebar = computed(() => {
      // Se estou em mobile a sidebar sempre e renderizada
      return isDesktop.value ? isSidebarActive.value : true;
    });

    const isSidebarToggler = (target: EventTarget) => {
      if (!isElement(target)) return false;
      return target.classList.contains("sidebar-toggler");
    };

    const onMenuItemClick = (event: SideBarMenuItemClickEvent) => {
      // Se eu cliquei num item que não tem subitens eu
      // cliquei num link e irei navegar para uma nova rota
      const willNavigateToNewRoute = !event.item.items;

      // Se eu selecionei uma rota do meu menu de navegação é esperado
      // que ele se feche para liberar a tela para o conteudo da rota
      if (willNavigateToNewRoute && !isDesktop.value) CLOSE_SIDEBAR();
    };

    const onClickOutside = ({ target }: Event) => {
      if (!isSidebarActive.value) return;

      // Se o elemento que cliquei ja fecha ou abre a sidebar a fecho pois
      // o elemento tem um handler para tal e nao quero interferir com ele
      const clickedOnSidebarToggler = target && isSidebarToggler(target);

      // Se estou exibindo a sidebar num dispositivo mobile ou no modo overlay
      // entao a fecho ao clicar fora dela, pois nao é o comportamento esperado
      const shouldClose = (!isDesktop.value || sidebarMode.value === "overlay") && isSidebarActive.value;

      if (shouldClose && !clickedOnSidebarToggler) CLOSE_SIDEBAR();
    };

    const { isLoggedIn } = useAuth();

    return {
      sidebarClass,
      sidebarRoutes,
      shouldRenderSidebar,

      isLoggedIn,
      layoutColor,
      isSidebarActive,

      onClickOutside,
      onMenuItemClick,
    };
  },
});
