/**
 * Verifica se string é uma data no formato dd/mm/yyyy
 */
export function isLocalDate(data: string) {
  return /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(data);
}

/**
 * Verifica se string é uma data no formato ISO8601
 */
export function isIsoDate(data: string) {
  if (!/\d{4}-\d{2}-\d{2}/.test(data)) return false;

  try {
    return new Date(data).toISOString().substring(0, 10) === data;
  } catch {
    return false;
  }
}

/**
 * Converte datas no formato DD/MM/YYYY para YYYY-MM-DD (ISO8601)
 *
 * ex: 10/10/2020 -> 2020-10-10
 */
export function localDateToIso(data: string): string {
  if (!data || data.length < 10) return "";

  return data.substring(0, 10).split("/").reverse().join("-");
}

/**
 * converte datas no formato ISO YYYY-MM-DD para DD/MM/YYYY
 *
 * ex: 2020-10-10 -> 10/10/2020
 */
export function isoToLocalDate(data: string) {
  if (!data || data.length < 10) return "";

  return data.substring(0, 10).split("-").reverse().join("/");
}

/**
 * converte datas no formato ISO 8601 para localeDate
 */
export function isoToLocalDateTime(data: string) {
  return new Date(data.replace(/-/g, "/").replace(/T.+/, "")).toLocaleDateString();
}
