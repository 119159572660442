import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutSidebar = _resolveComponent("LayoutSidebar")!
  const _component_LayoutTopBar = _resolveComponent("LayoutTopBar")!
  const _component_RouterViewWrapper = _resolveComponent("RouterViewWrapper")!
  const _component_LayoutConfig = _resolveComponent("LayoutConfig")!
  const _component_LayoutFooter = _resolveComponent("LayoutFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.containerClass)
  }, [
    (_ctx.isLoggedIn || _ctx.$route.meta.isOfflineRoute)
      ? (_openBlock(), _createBlock(_component_LayoutSidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoggedIn || _ctx.$route.meta.requireLogin === false)
        ? _withDirectives((_openBlock(), _createBlock(_component_LayoutTopBar, { key: 0 }, null, 512)), [
            [_vShow, _ctx.$route.meta.hideHeader !== true]
          ])
        : _createCommentVNode("", true),
      (!_ctx.authState.isLoggingOut)
        ? (_openBlock(), _createBlock(_component_RouterViewWrapper, {
            key: 1,
            style: _normalizeStyle(_ctx.routerViewStyle)
          }, null, 8, ["style"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isLoggedIn || _ctx.$route.meta.isOfflineRoute)
      ? _withDirectives((_openBlock(), _createBlock(_component_LayoutConfig, { key: 1 }, null, 512)), [
          [_vShow, _ctx.$route.meta.hideConfig !== true]
        ])
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn || _ctx.$route.meta.isOfflineRoute)
      ? _withDirectives((_openBlock(), _createBlock(_component_LayoutFooter, { key: 2 }, null, 512)), [
          [_vShow, _ctx.$route.meta.hideFooter !== true]
        ])
      : _createCommentVNode("", true)
  ], 2))
}