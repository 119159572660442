import { AxiosError } from "axios";

interface ApiErrorResponse {
  path: string;
  error: string;
  message?: string | Record<string, unknown> | Array<unknown>;
  timestamp: string;
  statusCode: number;
  isApiError: true;
}

export const isAxiosError = (error: Error): error is AxiosError => {
  return (error as AxiosError).isAxiosError === true;
};

export const isApiErrorResponseData = (responseData: unknown): responseData is ApiErrorResponse => {
  const cast = responseData as ApiErrorResponse;
  return typeof cast === "object" && cast.statusCode !== undefined;
};

function logAxiosError(error: AxiosError) {
  if (error.response) {
    console.log("error.response");
    console.log(error.response.headers);
    console.log(error.response.data);
  }
  if (error.request) {
    console.log("error.request");
    console.log(error.request);
  }
}

/**
 * Retorna os dados de erro da API, caso não seja um axios error
 * ou não seja um erro padronizado pela api retorna a mensagem padrão
 */
export function debugAxiosError(error: AxiosError, defaultMessage = "Erro ao se comunicar com a plataforma"): string {
  if (!isAxiosError(error)) {
    console.warn(error);
    return defaultMessage;
  }

  const { response } = error;

  logAxiosError(error);

  if (!response || !isApiErrorResponseData(response.data)) return defaultMessage;

  // @ts-ignore
  return typeof response.data.message === "string" ? response.data.message : defaultMessage;
}
