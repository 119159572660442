export function addClass(element: Element, className: string) {
  if (element.classList) element.classList.add(className);
  else element.className += " " + className;
}

export function removeClass(element: Element, className: string) {
  if (element.classList) {
    element.classList.remove(className);
    return;
  }

  // see: https://github.com/HubSpot/youmightnotneedjquery/issues/35
  const regex = new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi");
  element.className = element.className.replace(regex, " ");
}
