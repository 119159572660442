
import { defineAsyncComponent, defineComponent } from "vue";
import { useLoading } from "@/store/loading/loading";

export default defineComponent({
  name: "RouterViewWrapper",
  components: {
    RLoading: defineAsyncComponent(() => import("@/components/loading/RLoading.vue")),
  },
  setup() {
    const { estaCarregando } = useLoading();

    return {
      estaCarregando,
    };
  },
});
