import { PERMISSAO } from "@/constants/permissoes";
import { AppRoute } from "../router";

export const grupoRoutes: AppRoute[] = [
  {
    path: "/grupos",
    component: () => import("@pages/grupos/PageTabelaGrupos.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_TENANCIA],
    },
  },
  {
    path: "/grupos/cadastro",
    component: () => import("@pages/grupos/PageCadastroGrupo.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.INSERT_TENANCIA],
    },
  },
  {
    path: "/grupos/:id_grupo",
    component: () => import("@pages/grupos/PageGrupo.vue"),
    props: ({ params }) => ({ idGrupo: Number(params.id_grupo) }),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_TENANCIA],
    },
  },
];
