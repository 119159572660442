import { Ficha, HistoricoRejeicaoFicha } from "@/typescript/monitoramento/ficha-types";
import { sentido } from "@/typescript/monitoramento/monitoramento-types";
import { localDateToIso } from "@/utils/date";
import { Point } from "geojson";
import { api } from "./api";
import { FetchFicha } from "@p/monitoramento/fichas/terrapleno-contencao/TabelaFichasRealocar.vue";

export interface FetchFichasParams {
  uuid?: string;

  is_ultima_versao?: boolean;

  relations?: (
    | "leiaute"
    | "monitoramento"
    | "usuario_aprovante"
    | "usuario_aprovante.pessoa"
    | "usuario_liberante"
    | "usuario_liberante.pessoa"
    | "usuario_cadastrante"
    | "usuario_cadastrante.pessoa"
    | "terraplenos_contencao"
    | "terraplenos_contencao.imagens"
    | "terraplenos_contencao.imagens.foto"
    | "historico_rejeicao"
    | "historico_rejeicao.usuario"
    | "historico_rejeicao.usuario.pessoa"
  )[];
}

export interface CreateFichaTerraplenoContencaoDTO {
  // ---------------------------- FKS ----------------------------

  uuid: string;

  id_monitoramento: number;

  // ---------------------------- DADOS DE CADASTRO ----------------------------

  tipo: "terrapleno" | "contenção";

  data_inspecao: string;

  km_inicial: number;

  km_final: number;

  localizacao: number;

  // ---------------------------- CADASTRAMENTO ----------------------------

  sentido_rodovia: sentido;

  point: Point;

  // ---------------------------- DADOS GEOMETRICOS DO TERRAPLENO ----------------------------

  extensao_terrapleno: number;

  altura_terrapleno: number;

  inclinacao_terrapleno: number;

  distancia_acostamento: number;

  // ---------------------------- Caracteristicas Gerais ----------------------------

  tipo_terrapleno: "aterro" | "corte";

  tipo_relevo: "ondulado" | "suave" | "montanhoso";

  tipo_vegetacao: "arbustiva" | "arbórea" | "rasteira" | "nenhuma";

  densidade_vegetacao: "esparsa" | "alta" | "média";

  // ---------------------------- Dados da Estrutura de Contenção ----------------------------

  tipo_contencao:
    | "gabião"
    | "terra armada"
    | "não se aplica"
    | "muro de arrimo"
    | "concreto projetado"
    | "contenção em geotêxtil";

  extensao_estrutura: number;

  altura_estrutura: number;

  tipo_ancoragem: "não se aplica" | "satisfatória" | "cabeça danificada" | "ancoragem danificada";

  elementos_concreto_aco:
    | "ruptura"
    | "corrosão"
    | "deformação"
    | "satisfatória"
    | "não se aplica"
    | "armadura exposta"
    | "concreto desagregado";

  // ---------------------------- Drenagem ----------------------------

  drenagem_superficial: "natural" | "construída" | "inexistente";

  drenagem_superficial_condicao: "não se aplica" | "insuficiente" | "satisfatória" | "obstruida" | "danificada";

  drenagem_subterranea: "natural" | "construída" | "inexistente";

  drenagem_subterranea_tipo: "não se aplica" | "insuficiente" | "satisfatória" | "obstruida" | "danificada";

  drenagem_subterranea_condicao: "não se aplica" | "insuficiente" | "satisfatória" | "obstruida" | "danificada";

  // ---------------------------- Condições Gerais de Saturação ----------------------------

  presenca_agua: "sem água" | "áreas saturadas" | "úmido" | "surgências localizadas";

  tipo_ocorrencia:
    | "não se aplica"
    | "satisfatório"
    | "erosão"
    | "escorregamento"
    | "queda de blocos"
    | "desagregação superficial"
    | "outros";

  causas_provaveis:
    | "não se aplica"
    | "satisfatório"
    | "inclinação do maciço"
    | "deficiência de proteção superficial"
    | "fundação"
    | "compactação inadequada";

  tem_passivo_ambiental: boolean;

  passivo_ambiental: string;

  // ---------------------------- Gravidade da situação ----------------------------

  nivel_risco: 0 | 1 | 2 | 3;

  tem_risco_para_outros_elementos: boolean;

  // ---------------------------- Observações Gerais ----------------------------

  observacao?: string;

  // ---------------------------- FOTOS ----------------------------

  foto_anterior: File | null;
  foto1: File;
  foto2: File;
  foto3: File;
  croqui: File;
}

/**
 * Basicamente o mesmo que CreateFichaTerraplenoContencaoDTO mas com fotos opcionais
 * e as fotos atuais é um array que informa as substituições
 */
interface RevisaFichaTerraplenoContencaoDTO
  extends Omit<CreateFichaTerraplenoContencaoDTO, "foto_anteior" | "foto1" | "foto2" | "foto3" | "croqui"> {
  /**
   * Se null não é alterado
   */
  foto_anterior: File | null;
  /**
   * Se null não é alterado
   */
  croqui: File | null;
  /**
   * Array de fotos novas
   */
  fotosAtuais: File[];
  /**
   * Ids das public files a serem substituidas pelas fotosAtuais
   */
  fotosAtuaisSubstituidas: number[];
}

// ------------------------------------ GET ------------------------------------

/**
 * Busca o relatório em excel de uma ficha de estruturas
 * de terrapleno e contenção
 */
export async function apiFetchRelatorioFichaTerrapleno(
  idFichaTerrapleno: number
): Promise<{ url: string; filename: string }> {
  return api()
    .get(`terrapleno-estrutura-contencao/${idFichaTerrapleno}/relatorio-excel`, { responseType: "blob" })
    .then((res) => {
      const contentHeader = res.headers["content-disposition"];

      const filename = typeof contentHeader === "string" ? contentHeader.split("filename=")[1] : "Download";

      return { url: URL.createObjectURL(new Blob([res.data])), filename };
    });
}

/**
 * Busca fichas de um monitoramento específico
 */
export async function apiFetchFichasFromMonitoramento(
  idMonitoramento: number,
  params?: FetchFichasParams
): Promise<Ficha[]> {
  const { data: fichas } = await api().get(`/monitoramentos/${idMonitoramento}/fichas`, { params });
  return fichas;
}

/**
 * Busca todas as versões de uma ficha com uuid específico,
 * se o usuário tem permissão para visualizar apenas as
 * versões que foram liberadas são buscadas
 */
export async function apiFetchVersoesFicha(uuid: string, params?: FetchFichasParams): Promise<Ficha[]> {
  const { data: versoes } = await api().get(`/fichas/${uuid}`, { params });
  return versoes;
}

// ------------------------------------ POST ------------------------------------

/**
 * Revoga a avaliação de uma ficha, deletando o seu historico de rejeicao
 * se a tem ou setando data_aprovacao como null
 */
export async function apiRevogaAvaliacaoFicha(uuid: string): Promise<void> {
  return api().post(`/fichas/${uuid}/revogar-avaliacao`);
}

export async function apiAlterarMonitoramentoFicha(uuid: string, idMonitoramento: number): Promise<void> {
  const dto = {
    id_monitoramento: idMonitoramento,
  };
  return api().post(`/fichas/${uuid}/alterar-monitamento`, dto);
}

/**
 * Aprova a ultima versão da ficha com o uuid especificado, marcando a
 * data/hora em data_aprovação como a data atual
 */
export async function apiAprovaUltimaVersaoFicha(uuid: string): Promise<void> {
  return api().post(`/fichas/${uuid}/aprovar`);
}

/**
 * Rejeita a ultima versao da ficha com o uuid informado,
 * retornando o historico de rejeicao criado
 */
export async function apiRejeitaUltimaVersaoFicha(
  uuid: string,
  dto: { motivo: string }
): Promise<HistoricoRejeicaoFicha> {
  const { data: historico } = await api().post(`/fichas/${uuid}/rejeitar`, dto);
  return historico as unknown as HistoricoRejeicaoFicha;
}

/**
 * Cria uma ficha de terraplenos e estrutura de contencao
 *
 * @returns uuid da ficha criada
 */
export async function apiCadastraFichaTerraplenoContencao(dto: CreateFichaTerraplenoContencaoDTO): Promise<number> {
  const { foto_anterior, foto1, foto2, foto3, croqui, ...data } = dto;

  data.data_inspecao = localDateToIso(data.data_inspecao);

  const formData = new FormData();
  if (foto_anterior) formData.append("foto_anterior", foto_anterior);
  formData.append("fotos_atuais", foto1);
  formData.append("fotos_atuais", foto2);
  formData.append("fotos_atuais", foto3);
  formData.append("croqui", croqui);
  formData.append("data", JSON.stringify(data));

  const { data: uuid } = await api().post("/terrapleno-estrutura-contencao", formData);
  return uuid as unknown as number;
}

/**
 * Revisa uma ficha de terraplenos e estrutura de contencao, criando uma nova versão da mesma
 */
export async function apiRevisaFichaTerraplenoContencao(
  id: number,
  dto: RevisaFichaTerraplenoContencaoDTO
): Promise<number> {
  const { foto_anterior, fotosAtuais, croqui, ...data } = dto;

  data.data_inspecao = localDateToIso(data.data_inspecao);

  const formData = new FormData();

  fotosAtuais.map((file) => {
    formData.append("fotos_atuais", file);
  });

  if (foto_anterior) formData.append("foto_anterior", foto_anterior);
  if (croqui) formData.append("croqui", croqui);

  formData.append("data", JSON.stringify(data));

  const { data: responseData } = await api().post(`/terrapleno-estrutura-contencao/${id}/revisar`, formData);
  return responseData as unknown as number;
}

// ------------------------------------ PUT ------------------------------------

/**
 * Seta a ultima versão da ficha com o uuid informado como
 * liberada ou não para a avaliação de agências reguladoras
 */
export function apiSetLiberadoUltimaVersaoFicha(uuid: string, liberado: boolean): Promise<void> {
  return api().put(`/fichas/${uuid}`, { liberado });
}

//relocate ficha
export async function apiRelocateRecord(params?: any): Promise<FetchFicha> {
  const { data: data } = await api().get(`/terrapleno-estrutura-contencao/list-for-relocation`, { params });

  return data;
}

export async function apiFichaByUUID(uuid: string): Promise<any> {
  return await api().get("/fichas/by_uuid/" + uuid);
}
