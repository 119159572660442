import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4755d822"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "items-center justify-center h-screen p-d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_loading = _resolveComponent("r-loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, { class: "router-view-wrapper" }, {
    default: _withCtx(({ Component }) => [
      (_ctx.estaCarregando)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_r_loading)
          ]))
        : _createCommentVNode("", true),
      _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(Component), null, null, 512)), [
        [_vShow, _ctx.estaCarregando === false]
      ])
    ]),
    _: 1
  }))
}