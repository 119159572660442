import { useAuth } from "@/store/auth/auth";

/**
 * Busca a rota HOME de acordo a situação do usuário
 */
export function getHomeRoute(): string {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn.value) return "/login";

  return "/home";
}
