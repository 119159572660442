export const primeVueBrLocaleOptions = {
  startsWith: "Começa com",
  contains: "Contém",
  notContains: "Não Contém",
  endsWith: "Termina com",
  equals: "Igual a",
  notEquals: "Diferente de",
  noFilter: "Sem Filtro",
  lt: "Menor",
  lte: "Menor ou igual",
  gt: "Maior",
  gte: "Maior ou igual",
  dateIs: "Data é",
  dateIsNot: "Data não é",
  dateBefore: "Data anterior de",
  dateAfter: "Data depois de",
  clear: "Limpar",
  apply: "Aplicar",
  matchAll: "Procurar todos",
  matchAny: "Procurar qualquer",
  addRule: "Adicionar regra",
  removeRule: "Remover regra",
  accept: "Sim",
  reject: "Não",
  choose: "Escolher",
  upload: "Upload",
  cancel: "Cancelar",
  dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  dayNamesShort: ["Do", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  dayNamesMin: ["Do", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  today: "Hoje",
  acceptLabel: "Confirmar",
  rejectLabel: "Cancelar",
  weekHeader: "Ds",
  firstDayOfWeek: 0,
  dateFormat: "dd/mm/yy",
  weak: "Fraco",
  medium: "Médio",
  strong: "Forte",
  passwordPrompt: "Insira uma senha",
  emptyFilterMessage: "Nenhum resultado encontrado",
  emptyMessage: "Nenhuma opção disponível",
};
