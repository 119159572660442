import { loadFromLS, syncWithLS } from "../persist-state";
import { computed, Ref, ref } from "vue";

/*
 | Theme: 
 | 
 | Aqui devem ficar todos os dados referentes
 | tema da aplicação, como darkMode, dadosDoLayout,
 | cores, modo de exibição da sidebar, etc.
 */

export type LayoutColor = "dark" | "light";
export type SidebarMode = "static" | "overlay";
export type InputStyle = "outlined" | "filled";

const layoutColor: Ref<LayoutColor> = ref(loadFromLS("layoutColor", "dark"));
const inputStyle: Ref<InputStyle> = ref(loadFromLS("inputStyle", "outlined"));
const sidebarMode: Ref<SidebarMode> = ref(loadFromLS("sidebarMode", "static"));

/**
 * Se o efeito de ripple esta ativo
 */
const isRippleActive: Ref<boolean> = ref(loadFromLS("isRippleActive", true));

/**
 * Se a sidebar esta ativa (em exibicao). caso o usuario inicialize
 * o app num dispositivo mobile a sidebar é inicializada como inativa
 */
const isSidebarActive: Ref<boolean> = ref(window.innerWidth > 1024);

const OPEN_SIDEBAR = () => (isSidebarActive.value = true);
const CLOSE_SIDEBAR = () => (isSidebarActive.value = false);
const TOGGLE_SIDEBAR = () => (isSidebarActive.value = !isSidebarActive.value);

const SET_INPUT_STYLE = (value: InputStyle) => (inputStyle.value = value);
const SET_SIDEBAR_MODE = (value: SidebarMode) => (sidebarMode.value = value);
const SET_LAYOUT_COLOR = (value: LayoutColor) => (layoutColor.value = value);

const TOGGLE_RIPPLE = (value: boolean) => (isRippleActive.value = value);

syncWithLS({ inputStyle, layoutColor, sidebarMode, isRippleActive });

export const useTheme = () => ({
  sidebarMode: computed(() => sidebarMode.value),

  inputStyle: computed(() => inputStyle.value),
  layoutColor: computed(() => layoutColor.value),

  isRippleActive: computed(() => isRippleActive.value),
  isSidebarActive: computed(() => isSidebarActive.value),

  TOGGLE_RIPPLE,
  SET_SIDEBAR_MODE,

  SET_INPUT_STYLE,
  SET_LAYOUT_COLOR,

  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR,
});
