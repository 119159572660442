
import LayoutSidebarMenuItems from "./LayoutSidebarMenuItems.vue";
import { sidebarItem } from "../../router/dashboard-sidebar-routes";
import { defineComponent, PropType } from "vue";

export interface SideBarMenuItemClickEvent {
  originalEvent: Event;
  item: sidebarItem;
}

export default defineComponent({
  name: "LayoutSidebarMenu",

  components: {
    LayoutSidebarMenuItems,
  },

  props: {
    items: {
      type: Array as PropType<sidebarItem[]>,
      default: () => [],
    },
  },

  emits: ["menuitem-click"],

  setup(_props, context) {
    const onMenuItemClick = (event: { originalEvent: Event; item: sidebarItem }) => {
      context.emit("menuitem-click", event);
    };

    return { onMenuItemClick };
  },
});
