import { PERMISSAO } from "@/constants/permissoes";
import { useAuth } from "@/store/auth/auth";
import { AppRoute } from "../router";

export const userRoutes: AppRoute[] = [
  {
    path: "/meu-perfil",
    component: () => import("@/pages/usuarios/perfil-usuario/PagePerfilUsuario.vue"),
    props: () => {
      const { state: authState } = useAuth();
      // Como a rota só carrega se esta logado e ao deslogar nela authState seta user como
      // null utilizo 0 como fallback para nao disparar erros ao sair da rota por logout
      return { idUsuario: authState.user?.id || 0 };
    },
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/usuarios/:id_usuario",
    component: () => import("@/pages/usuarios/perfil-usuario/PagePerfilUsuario.vue"),
    props: ({ params }) => ({ idUsuario: Number(params.id_usuario) }),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/usuarios/cadastro",
    component: () => import("@/pages/usuarios/PageCadastroUsuario.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.INSERT_USUARIO],
    },
  },
];
