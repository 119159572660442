
// Components
import AppDashboard from "./layout/AppDashboard.vue";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

// Utils
import { defineComponent, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { useAuth } from "./store/auth/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { AppDashboard, Toast, ConfirmDialog },

  setup() {
    const { isLoggedIn } = useAuth();
    const router = useRouter();
    const toast = useToast();

    watch(router.currentRoute, () => {
      toast.removeAllGroups();
    });

    return { isLoggedIn };
  },
});
