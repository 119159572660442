import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { rotasMonitoramento } from "./routes/monitoramento-rodoviario-routes";
import { commonRoutes } from "./routes/common-routes";
import { errorRoutes } from "./routes/error-routes";
import { PERMISSAO } from "@/constants/permissoes";
import { authRoutes } from "./routes/auth-routes";
import { userRoutes } from "./routes/user-routes";
import { grupoRoutes } from "./routes/grupos-routes";
import { useAuth } from "@/store/auth/auth";
import { useLoading } from "@/store/loading/loading";

interface SomePermissions {
  /**
   * Se a rota aceita que o usuário tenha ao menos uma de N permissões
   */
  some: true;
  value: PERMISSAO[];
}

interface RouteMeta {
  /**
   * Se a usuário precisa estar logado para acessar a rota
   */
  requireLogin?: boolean;

  /**
   * Se a rota precisa que não tenha usuário logada para acessa-la
   */
  requireLogoff?: boolean;

  /**
   * Indica que é uma rota offline, ou seja, não
   */
  isOfflineRoute?: boolean;

  /**
   * Permissões necessárias para acessar a rota, se falhar o usuário
   * é redirecionado para pagina de acesso negado
   */
  requiredPermissions?: RouteRequiredPermissions;

  /**
   * Se true então o padding em volta da router view não é aplicado
   */
  fullpage?: boolean;

  /**
   * Se o footer do layout não deve ser exibido para essa página
   */
  hideFooter?: boolean;

  /**
   * Se o header do layout não deve ser exibido para essa página
   */
  hideHeader?: boolean;

  /**
   * Se o botão de configurar layout não deve ser exibido para essa página
   */
  hideConfig?: boolean;
}

export type RouteRequiredPermissions = SomePermissions | PERMISSAO | PERMISSAO[];

export type AppRoute = RouteRecordRaw & {
  meta?: RouteMeta;
};

export const isPermissionObj = (v: unknown): v is SomePermissions => {
  return typeof v !== "string" && !Array.isArray(v) && (v as SomePermissions).some === true;
};

const router = createRouter({
  history: createWebHistory(),
  routes: [...commonRoutes, ...authRoutes, ...errorRoutes, ...rotasMonitoramento, ...userRoutes, ...grupoRoutes],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const toMeta = to.meta as RouteMeta;

  const { isLoggedIn, userCan } = useAuth();
  const { DEFINIR_ESTA_CARREGANDO } = useLoading();
  const { requireLogin, requireLogoff, requiredPermissions } = toMeta;

  if (requireLogin && !isLoggedIn.value) return next("/login");
  if (requireLogoff && isLoggedIn.value) return next(false);

  if (requiredPermissions) {
    const canAccess = isPermissionObj(requiredPermissions)
      ? userCan(requiredPermissions.value, { some: true })
      : userCan(requiredPermissions);

    const perNames = isPermissionObj(requiredPermissions)
      ? requiredPermissions.value + `&some=true`
      : requiredPermissions;

    if (!canAccess) return next(`/acesso-negado/?per=${perNames}&prev=${to.path}`);
  }

  DEFINIR_ESTA_CARREGANDO(true);

  return next();
});

export default router;
