// Side-effect
import "./register-service-worker";
import "./typescript/global";

// App / Vue
import App from "./App.vue";
import { createApp } from "vue";
import router from "./router/router";

// Prime
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import { primeVueBrLocaleOptions } from "./plugins/prime-vue";
import ConfirmationService from "primevue/confirmationservice";

// Diretivas
import CanDirective from "./directives/v-can";
import ClickOutside from "./directives/v-click-outside";

// Métodos globais (manter enxuto)
import { userCan } from "./store/auth/auth";

const app = createApp(App);

app.config.globalProperties.$can = userCan;

app.config.globalProperties.$table = console.table;

app
  .directive("click-outside", ClickOutside)
  .directive("can", CanDirective)
  .directive("tooltip", Tooltip)
  .directive("ripple", Ripple)
  .use(PrimeVue, { locale: primeVueBrLocaleOptions, ripple: true })
  .use(ConfirmationService)
  .use(ToastService)
  .use(router)
  .mount("#app");

export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GMAPS_API_KEY;
