import { PERMISSAO } from "@/constants/permissoes";
import { AppRoute } from "../router";

export const authRoutes: AppRoute[] = [
  {
    path: "/login",
    component: () => import("@p/auth/PageLogin.vue"),
    meta: {
      requireLogoff: true,
    },
  },
  {
    path: "/recuperar-senha",
    component: () => import("@p/auth/PageForgotPassword.vue"),
    meta: {
      requireLogoff: true,
    },
  },
  {
    path: "/redefinir-senha",
    component: () => import("@p/auth/PageResetPassword.vue"),
    props: (route) => ({ token: route.query.token }),
    meta: {
      requireLogoff: true,
    },
  },
  {
    path: "/usuarios",
    component: () => import("@/pages/usuarios/PageTabelaUsuarios.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_USUARIO],
    },
  },
  {
    path: "/niveis-acesso",
    component: () => import("@/pages/usuarios/PageTabelaNiveisAcesso.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_NIVEL_ACESSO],
    },
  },
];
