
// Utils
import { computed, defineComponent, ref } from "vue";
import { useTheme } from "@/store/theme/theme";
import { useAuth } from "@/store/auth/auth";
import { useRouter } from "vue-router";

// Components
import Button from "primevue/button";

export default defineComponent({
  components: {
    Button,
  },

  setup() {
    const { AUTH_LOGOUT, state: authState, isLoggedIn } = useAuth();
    const { layoutColor } = useTheme();
    const router = useRouter();

    const expanded = ref(false);

    const profileClass = computed(() => [
      {
        "layout-profile-dark": layoutColor.value === "dark",
        "layout-profile-light": layoutColor.value === "light",
      },
    ]);

    const onProfileClick = (event: Event) => {
      expanded.value = !expanded.value;
      event.preventDefault();
    };

    const onLogoutClick = () => {
      AUTH_LOGOUT();
      router.push("/login");
    };

    const nomePessoa = computed(() => authState.user?.pessoa?.nome || "");

    return { onProfileClick, expanded, onLogoutClick, nomePessoa, profileClass, isLoggedIn };
  },
});
