import { loadFromLS, syncWithLS } from "../persist-state";
import { computed, Ref, ref } from "vue";

const estaCarregando: Ref<Boolean> = ref(loadFromLS("estaCarregando", false));

const DEFINIR_ESTA_CARREGANDO = (value: Boolean) => (estaCarregando.value = value);

syncWithLS({ estaCarregando });

export const useLoading = () => ({
  estaCarregando: computed(() => estaCarregando.value),

  DEFINIR_ESTA_CARREGANDO,
});
