
// Components
import Button from "primevue/button";

// Utils
import { useSincronizadorFicha } from "@/store/monitoramento/sincronizador-fichas";
import { useTheme } from "@/store/theme/theme";
import { useAuth } from "@/store/auth/auth";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Button,
  },

  setup() {
    const { AUTH_LOGOUT, isLoggedIn } = useAuth();
    const { TOGGLE_SIDEBAR } = useTheme();
    const router = useRouter();

    const { state: syncState } = useSincronizadorFicha();

    const logout = () => {
      AUTH_LOGOUT();
      router.push("/login");
    };

    return { TOGGLE_SIDEBAR, logout, isLoggedIn, syncState };
  },
});
