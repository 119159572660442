import { AppRoute } from "../router";

export const errorRoutes: AppRoute[] = [
  {
    path: "/acesso-negado",
    component: () => import("@p/error/PageForbidden.vue"),
    props: ({ query }) => ({
      some: query.some === "true" ? true : false,
      previousRoute: typeof query.prev === "string" ? query.prev : "",
      requiredPermissions: typeof query.per === "string" ? query.per.split(",") : [],
    }),
  },
];
