<script>
import { defineComponent } from "vue";

export default defineComponent({ name: "LayoutFooter" });
</script>

<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px">Oliveira e Rae Engenharia LTDA</span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/_variables.scss";

.layout-footer {
  transition: margin-left $transitionDuration;
  background-color: $footerBgColor;
  padding: 1em 2em;

  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;

  img {
    vertical-align: middle;
  }

  .footer-text {
    vertical-align: middle;
  }
}

@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-footer {
        margin-left: 0;
      }
    }

    &.layout-static {
      .layout-footer {
        margin-left: 250px;
      }

      &.layout-static-sidebar-inactive {
        .layout-footer {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .layout-footer {
      margin-left: 0;
    }

    .layout-footer {
      display: none;
    }
  }
}
</style>
