import { Ref, watch, WatchOptions, WatchStopHandle } from "vue";

type PersistanceArray = Array<{
  value: Ref<unknown> | object;
  key: string;
}>;

const USED_LOCAL_STORAGE_KEYS: string[] = [];

const createKeyInUseMessage = (keyname: string) =>
  "KEY_IN_USE_ERROR" +
  "\n" +
  `Cannot persist on LocalStorage, key ${keyname} is already used` +
  "\n" +
  "hint: have you called syncWithLS() with the same key twice ?";

/**
 * Persists and sync set of key/value pairs on local storage, where key
 * is the localstorage key and value is a Ref or a reactive object
 *
 * @return a object containing the watch stop handlers.
 * the objects keys are the same localstorage keys and the values
 * are the respective handlers
 */
export function syncWithLS<T extends object>(state: PersistanceArray): Record<keyof T, WatchStopHandle>;

/**
 * Persits a state in local storage, its expected that state is a object
 * with values that are reactive objects or a Ref so its changes are tracked
 *
 * @return a object containing the watch stop handlers
 */
export function syncWithLS<T extends object>(state: T): Record<keyof T, WatchStopHandle>;

export function syncWithLS<T extends object>(state: PersistanceArray | T): Record<keyof T, WatchStopHandle> {
  const watchStopHandlers: any = {};

  if (Array.isArray(state)) {
    state.forEach((item) => {
      const { key, value } = item;

      if (isKeyInUse(key)) throw new Error(createKeyInUseMessage(key));

      USED_LOCAL_STORAGE_KEYS.push(key);

      watchStopHandlers[key] = persist(value, key);
    });

    return watchStopHandlers;
  }

  Object.entries(state).forEach(([key, value]) => {
    if (isKeyInUse(key)) throw new Error(createKeyInUseMessage(key));

    USED_LOCAL_STORAGE_KEYS.push(key);

    watchStopHandlers[key] = persist(value, key);
  });

  return watchStopHandlers;
}

/**
 * Loads a value from localstorage, using the default value as fallback
 */
export function loadFromLS<T>(key: string, defaultValue: T): T {
  const item = localStorage.getItem(key);
  return item ? parseJsonWithFallback(item) : defaultValue ?? null;
}

/**
 * Verifies if a localstorage key wasnt previously registered by this module.
 * note that this doesnt verify if the localstorage key is in use or, just if
 * this module already uses it.
 *
 * This is so way we can know if the user will register 2 watchers for the same
 * key, that would result in buggy behaviour since watchers woud overwrite each
 * other.
 */
function isKeyInUse(key: string) {
  return USED_LOCAL_STORAGE_KEYS.includes(key);
}

/**
 * Persists a rective value on localstorage by watching its changes
 */
function persist(value: Ref<unknown> | object, key: string, watcherOptions?: WatchOptions): WatchStopHandle {
  const cb = (newVal: Ref<unknown> | object) => {
    localStorage.setItem(key, stringfy(newVal));
  };

  const stopWatching = watch(value, cb, watcherOptions ?? { immediate: true });

  // Instead simply return the WatchStopHandle i need to
  // return a function that releases the key when the used key
  return () => {
    const idx = USED_LOCAL_STORAGE_KEYS.indexOf(key);
    if (idx !== -1) USED_LOCAL_STORAGE_KEYS.splice(idx, 1);

    stopWatching();
  };
}

/**
 * basically JSON.stringify that suports bigint
 */
function stringfy(val: unknown): string {
  if (typeof val === "string") return val;
  if (typeof val === "bigint") return String(val);

  return JSON.stringify(val);
}

function parseJsonWithFallback(str: string) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}
