import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-381e6f1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "version-container",
  style: { color: '#e3e3e3' }
}
const _hoisted_2 = {
  key: 1,
  class: "version-container",
  style: { color: '#030303' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutSidebarProfile = _resolveComponent("LayoutSidebarProfile")!
  const _component_LayoutSidebarMenu = _resolveComponent("LayoutSidebarMenu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Transition, { name: "layout-sidebar" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.sidebarClass)
      }, [
        _createVNode(_component_LayoutSidebarProfile),
        _createVNode(_component_LayoutSidebarMenu, {
          items: _ctx.sidebarRoutes,
          onMenuitemClick: _ctx.onMenuItemClick
        }, null, 8, ["items", "onMenuitemClick"]),
        (_ctx.layoutColor === 'dark')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "v 1.2.1"))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, "v 1.2.1"))
      ], 2)), [
        [_vShow, _ctx.shouldRenderSidebar],
        [_directive_click_outside, _ctx.onClickOutside]
      ])
    ]),
    _: 1
  }))
}