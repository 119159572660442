
import { computed, defineComponent, onBeforeUpdate, watch } from "vue";
import { useBreakpoints } from "../store/theme/breakpoints";
import { addClass, removeClass } from "../utils/css";
import { useTheme } from "../store/theme/theme";

import RouterViewWrapper from "../components/RouterViewWrapper.vue";
import LayoutSidebar from "./sidebar/LayoutSidebar.vue";
import LayoutTopBar from "./topbar/LayoutTopBar.vue";
import LayoutConfig from "./LayoutConfig.vue";
import LayoutFooter from "./LayoutFooter.vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@/store/auth/auth";

export default defineComponent({
  name: "AppDashboard",

  components: {
    LayoutTopBar,
    LayoutConfig,
    LayoutFooter,
    LayoutSidebar,
    RouterViewWrapper,
  },

  setup() {
    const { sidebarMode, inputStyle, isRippleActive, isSidebarActive, CLOSE_SIDEBAR } = useTheme();
    const { state: authState, isLoggedIn } = useAuth();
    const { isDesktop } = useBreakpoints();
    const router = useRouter();
    const route = useRoute();

    const containerClass = computed(() => {
      const mobileSidebarActive = !isDesktop.value && isSidebarActive.value;
      const overlaySidebarActive = isDesktop.value && isSidebarActive.value && sidebarMode.value === "overlay";

      return [
        {
          "layout-wrapper": isLoggedIn.value || route.meta.isOfflineRoute,
          "layout-static": sidebarMode.value === "static",
          "layout-overlay": sidebarMode.value === "overlay",

          // No mobile sempre é overlay, em desktop apenas se selecionado
          "layout-overlay-sidebar-active": overlaySidebarActive || mobileSidebarActive,

          // Remove o margem da esquerda do layout inteiro
          "layout-static-sidebar-inactive": isDesktop.value && !isSidebarActive.value && sidebarMode.value === "static",

          "p-input-filled": inputStyle.value === "filled",
          "p-ripple-disabled": !isRippleActive,
        },
      ];
    });

    const routerViewStyle = computed(() => {
      const padding = route.meta.fullpage ? "0px" : "2rem";

      return {
        padding,
      };
    });

    // Se estou com a sidebar aberta e o usuario selecionou uma nova rota, fecho ela
    watch(router.currentRoute, () => {
      if (isSidebarActive.value && !isDesktop.value) CLOSE_SIDEBAR();
    });

    onBeforeUpdate(() => {
      const isShowingMobileSidebar = isSidebarActive.value && !isDesktop.value;

      isShowingMobileSidebar
        ? addClass(document.body, "body-overflow-hidden")
        : removeClass(document.body, "body-overflow-hidden");
    });

    return { containerClass, routerViewStyle, authState, isLoggedIn };
  },
});
