import { PERMISSAO } from "@/constants/permissoes";
import { AppRoute } from "../router";

export const rotasMonitoramento: AppRoute[] = [
  // --------------------------------- OFFLINE ---------------------------------
  {
    path: "/offline/rodoviario/fichas/terrapleno-contencao",
    component: () => import("@p/monitoramento/fichas/terrapleno-contencao/PageCadastroFichaTerrapleno.vue"),
    meta: {
      hideFooter: true,
      requireLogin: false,
      isOfflineRoute: true,
    },
  },
  {
    path: "/offline/rodoviario/mapa",
    component: () => import("@p/monitoramento/offline/PageOfflineMapa.vue"),
    meta: {
      fullpage: true,
      hideHeader: true,
      hideFooter: true,
      hideConfig: true,
      requireLogin: false,
      isOfflineRoute: true,
    },
  },
  {
    path: "/offline/rodoviario/minhas-fichas",
    component: () =>
      import("@/pages/monitoramento/offline/tabela-ficha-terraplenos/PageOfflineTabelaFichasTerraplenos.vue"),
    meta: {
      requireLogin: false,
      isOfflineRoute: true,
    },
  },
  // --------------------------------- ONLINE ---------------------------------
  {
    path: "/rodoviario/mapa",
    component: () => import("@p/monitoramento/mapa/PageMapa.vue"),
    meta: {
      fullpage: true,
      hideFooter: true,
      hideConfig: true,
      hideHeader: true,

      requireLogin: true,
      requiredPermissions: {
        some: true,
        value: [PERMISSAO.SELECT_FICHA, PERMISSAO.SELECT_FICHA_LIBERADA],
      },
    },
  },
  {
    path: "/rodoviario/servicos",
    component: () => import("@/pages/monitoramento/tabela-servicos/PageTabelaServicos.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_SERVICO_MONITORAMENTO],
    },
  },
  {
    path: "/rodoviario/servicos/cadastro",
    component: () => import("@/pages/monitoramento/cadastro-servico/PageCadastroServicoMonitoramento.vue"),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: [PERMISSAO.INSERT_SERVICO_MONITORAMENTO],
    },
  },
  {
    path: "/rodoviario/servicos/:id_servico",
    component: () => import("@/pages/monitoramento/atualizar-servico/PageAtualizarServicoMonitoramento.vue"),
    props: ({ params }) => ({ idServico: Number(params.id_servico) }),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: [PERMISSAO.UPDATE_SERVICO_MONITORAMENTO],
    },
  },
  {
    path: "/rodoviario/servicos/:id_servico/monitoramentos",
    component: () => import("@/pages/monitoramento/tabela-monitoramentos/PageTabelaMonitoramentos.vue"),
    props: ({ params }) => ({ idServico: Number(params.id_servico) }),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_SERVICO_MONITORAMENTO],
    },
  },
  {
    path: "/rodoviario/servicos/:id_servico/monitoramentos/:id_monitoramento/fichas",
    component: () => import("@/pages/monitoramento/tabela-monitoramentos/PageTabelaFichasTerraplenos.vue"),
    props: ({ params }) => ({
      idServico: Number(params.id_servico),
      idMonitoramento: Number(params.id_monitoramento),
    }),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: [PERMISSAO.SELECT_SERVICO_MONITORAMENTO],
    },
  },
  {
    path: "/rodoviario/monitoramento/:id_monitoramento/fichas/terrapleno-contencao",
    component: () => import("@p/monitoramento/fichas/terrapleno-contencao/PageCadastroFichaTerrapleno.vue"),
    props: ({ params }) => ({
      idMonitoramento: Number(params.id_monitoramento),
    }),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: {
        some: true,
        value: [PERMISSAO.INSERT_FICHA, PERMISSAO.REVIEW_FICHA],
      },
    },
  },
  {
    path: "/rodoviario/monitoramento/:id_monitoramento/fichas/terrapleno-contencao/:uuid_ficha",
    component: () => import("@p/monitoramento/fichas/terrapleno-contencao/PageCadastroFichaTerrapleno.vue"),
    props: ({ params }) => ({
      uuidFicha: params.uuid_ficha,
      idMonitoramento: Number(params.id_monitoramento),
    }),
    meta: {
      hideFooter: true,
      requireLogin: true,
      requiredPermissions: PERMISSAO.REVIEW_FICHA,
    },
  },
  {
    path: "/rodoviario/relocating",
    component: () => import("@p/monitoramento/fichas/terrapleno-contencao/TabelaFichasRealocar.vue"),
    meta: {
      requireLogin: true,
      requiredPermissions: [PERMISSAO.RELOCATE_FICHA],
    },
  },
];
