import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_AppDashboard = _resolveComponent("AppDashboard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, { position: "top-right" }),
    _createVNode(_component_ConfirmDialog, { style: { maxWidth: '500px' } }),
    _createVNode(_component_AppDashboard)
  ], 64))
}