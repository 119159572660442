import { loadFromLS, syncWithLS } from "../persist-state";
import { DeepPartial } from "@/typescript/auxiliar-types";
import { PERMISSAO } from "@/constants/permissoes";
import { computed, reactive, readonly } from "vue";
import { LoginRes } from "@/api/auth-api";
import { merge } from "lodash";

/*
 | Auth: 
 | 
 | Aqui devem ficar toda e qualquer logica quanto
 | a autorização, como JWT, usuário logado, permissões
 | do usuário, etc.
 */

interface AuthState {
  user: User | null;
  jwt: string | null;

  // Flag utilitaria
  isLoggingOut: boolean;
}

export interface User {
  id: number;
  id_pessoa: number;
  id_nivel_acesso: number;

  created_at: string;
  updated_at: string;
  deleted_at: string | null;

  email: string;
  email_confirmado: boolean;

  /**
   * A senha não é carregada ou armazenada quando o usuário
   * loga na aplicação, essa prop é apenas por conveniência
   */
  senha?: string;

  acesso_bloqueado: boolean;

  pessoa?: Pessoa;
  foto_perfil?: PublicFile | null;
  nivel_acesso?: NivelAcesso;
}

export interface Pessoa {
  id: number;

  created_at: string;
  updated_at: string;
  deleted_at: string | null;

  nome: string;
  cpf: string | null;
  data_nascimento: string | null;

  usuario: User;
}

export interface NivelAcesso {
  id: number;

  created_at: string;
  updated_at: string;

  nome: string;
  descricao: string;

  usuarios?: User[];
  permissoes?: Permissao[];
}

export interface PublicFile {
  id: number;
  url: string;
  key: string;
}

export interface Permissao {
  id: number;
  id_modulo_sistema: number;

  nome: string;
  nome_formatado: PERMISSAO;
  descricao: string;

  modulo_sistema?: ModuloSistema;
}

export interface ModuloSistema {
  id: number;
  nome: string;
  descricao: string;
  nome_formatado: string;
}

export interface ApiJwt {
  type: "bearer";
  value: string;
}

const state: AuthState = reactive(loadFromLS("authState", { user: null, jwt: null, isLoggingOut: false }));

const AUTH_LOGIN = (loginData: LoginRes) => {
  state.user = loginData.user;
  state.jwt = loginData.token.value;
};

const AUTH_LOGOUT = () => {
  state.isLoggingOut = true;
  state.user = null;
  state.jwt = null;
  state.isLoggingOut = false;
};

const UPDATE_USER = (data: DeepPartial<User>) => {
  state.user = merge(state.user, data);
};

syncWithLS({ authState: state });

interface UserCanOptions {
  /**
   * Se conter uma das permissoes é suficiente validar
   */
  some?: boolean;
}

/**
 * Verifica se o usuário tem uma permissao ou um conjunto delas
 */
export function userCan(permissoes: PERMISSAO | PERMISSAO[], options?: UserCanOptions): boolean {
  const { user } = state;

  if (!user) return false;

  if (!Array.isArray(permissoes)) permissoes = [permissoes];

  const permissoesUsuario = (user.nivel_acesso?.permissoes ?? []).map((p) => p.nome_formatado);

  return options?.some
    ? permissoes.some((permissao) => permissoesUsuario.includes(permissao))
    : permissoes.every((permissao) => permissoesUsuario.includes(permissao));
}

export const useAuth = () => ({
  AUTH_LOGIN,
  AUTH_LOGOUT,

  UPDATE_USER,

  // Também exporto essa função aqui por conveniencia
  userCan,

  isLoggedIn: computed((): boolean => state.jwt !== null),

  state: readonly(state),
});
